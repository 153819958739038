<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <router-link
              v-if="$currentUserCan($permissions.PERM_VIEW_APP_ADMIN)"
              :to="{ name: 'Reseller Page' }"
              v-slot="{ href, navigate }"
              custom
            >
              <a :href="href" @click="navigate">
                <i class="far fa-long-arrow-left"></i>
              </a>
            </router-link>
            <h3>{{ $t("RESELLER_INVOICES.RESELLER_INVOICES_LIST") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="../../../../../public/img/kw-home.svg" alt="icon" />
                <span>{{ $t("APPS.RESELLER_MODULE") }}</span>
              </li>
              <li>
                <span>{{
                  $t("RESELLER_INVOICES.RESELLER_INVOICES_LIST")
                }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-wrapper-header-right">
          <base-button
            class="kw-button add"
            icon
            size="sm"
            @click="openResellerInvoiceCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_RESELLER_INVOICES)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("RESELLER_INVOICES.ADD_RESELLER_INVOICE") }}
            </span>
          </base-button>
        </div>
      </div>

      <reseller-invoice-list-table
        @onViewResellerInvoice="openResellerInvoiceViewModal"
        @onEditResellerInvoice="openResellerInvoiceEditModal"
        @onDeleteResellerInvoice="deleteResellerInvoice"
        @onAddResellerInvoice="openResellerInvoiceCreateModal"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewResellerInvoiceModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewResellerInvoiceModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          v-if="openResellerInvoice"
          class="resizable-wrapper-content"
          v-resizable-modal="'RESELLER_INVOICE_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeResellerInvoiceModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("RESELLER_INVOICES.VIEW_RESELLER_INVOICE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-reseller-invoice-page
              v-if="openResellerInvoice"
              :resellerInvoiceId="openResellerInvoice.id"
              @onEditResellerInvoice="openResellerInvoiceEditModal"
              @onDeleteResellerInvoice="deleteResellerInvoice"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditResellerInvoiceModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditResellerInvoiceModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'RESELLER_INVOICE'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeResellerInvoiceModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("RESELLER_INVOICES.EDIT_RESELLER_INVOICE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <!--<base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openResellerInvoiceViewModal(openResellerInvoice)"
                >
                  <i class="fal fa-eye"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeResellerInvoiceModal">
                <i class="fal fa-times"></i>
              </button>-->
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-reseller-invoice-page
              v-if="openResellerInvoice"
              :resellerInvoiceId="openResellerInvoice.id"
              @onViewResellerInvoice="openResellerInvoiceViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddResellerInvoiceModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddResellerInvoiceModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'RESELLER_INVOICE'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeResellerInvoiceModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("RESELLER_INVOICES.ADD_RESELLER_INVOICE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-reseller-invoice-page
              @onViewResellerInvoice="openResellerInvoiceViewModal"
              @onCloseResellerInvoiceModal="closeResellerInvoiceModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import { INVOICE_STATUS_DRAFT } from "@/constants/invoices";
import ResellerInvoiceListTable from "./partials/ResellerInvoiceListTable.vue";
import EditResellerInvoicePage from "./components/EditResellerInvoiceComponent.vue";
import AddResellerInvoicePage from "./components/AddResellerInvoiceComponent.vue";
import ViewResellerInvoicePage from "./components/ViewResellerInvoiceComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";

export default {
  layout: "DashboardLayout",

  components: {
    WrapperExpandButton,
    ResellerInvoiceListTable,
    EditResellerInvoicePage,
    AddResellerInvoicePage,
    ViewResellerInvoicePage,
    [Button.name]: Button,
  },

  mixins: [],

  computed: {},

  watch: {},

  data() {
    const locationId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewResellerInvoiceModalOpened = false;
    let isEditResellerInvoiceModalOpened = false;
    let isAddResellerInvoiceModalOpened = false;
    let openResellerInvoice = null;
    if (locationId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewResellerInvoiceModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditResellerInvoiceModalOpened = true;
      }
      openResellerInvoice = { id: locationId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddResellerInvoiceModalOpened = true;
    }
    return {
      isViewResellerInvoiceModalOpened: isViewResellerInvoiceModalOpened,
      isEditResellerInvoiceModalOpened: isEditResellerInvoiceModalOpened,
      isAddResellerInvoiceModalOpened: isAddResellerInvoiceModalOpened,
      openResellerInvoice: openResellerInvoice,
      renderKey: 1,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
    };
  },

  methods: {
    openResellerInvoiceCreateModal() {
      this.closeResellerInvoiceModal();
      this.isAddResellerInvoiceModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerInvoices",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openResellerInvoiceViewModal(resellerinvoice, reRender = false) {
      this.closeResellerInvoiceModal();
      this.openResellerInvoice = resellerinvoice;
      this.isViewResellerInvoiceModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerInvoices",
          query: {
            id: this.openResellerInvoice.id,
            action: QUERY_ACTIONS_VIEW,
          },
        }).href
      );
    },
    openResellerInvoiceEditModal(resellerinvoice) {
      this.closeResellerInvoiceModal();
      this.openResellerInvoice = resellerinvoice;
      this.isEditResellerInvoiceModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerInvoices",
          query: {
            id: this.openResellerInvoice.id,
            action: QUERY_ACTIONS_EDIT,
          },
        }).href
      );
    },
    closeResellerInvoiceModal() {
      this.isAddResellerInvoiceModalOpened = false;
      this.isViewResellerInvoiceModalOpened = false;
      this.isEditResellerInvoiceModalOpened = false;
      this.openResellerInvoice = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List ResellerInvoices",
          query: {},
        }).href
      );
    },

    async deleteResellerInvoice(resellerInvoice) {
      const confirmation = await swal.fire({
        text: this.$t("RESELLER_INVOICES.DELETE_THIS_RESELLER_INVOICE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "resellerInvoices/destroy",
            resellerInvoice.id
          );
          this.renderKey++;
          this.closeResellerInvoiceModal();
          this.$notify({
            type: "success",
            message: this.$t("RESELLER_INVOICES.RESELLER_INVOICE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
