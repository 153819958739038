var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page-wrapper-table"},[_c('div',{staticClass:"page-wrapper-table-header"},[_c('div',{staticClass:"page-wrapper-table-header-left"},[_c('base-input',{staticClass:"search",attrs:{"type":"search","prepend-icon":"far fa-search","placeholder":_vm.$t('COMMON.SEARCH'),"clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),(!_vm.filterStatus)?_c('el-select',{attrs:{"placeholder":_vm.$t('COMMON.STATUS')},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}},[_c('el-option',{attrs:{"value":0,"label":_vm.$t('COMMON.ALL_STATUSES')}}),_vm._l((_vm.statusesOptions),function(value,key){return _c('el-option',{key:key,attrs:{"value":key,"label":value}})})],2):_vm._e(),(
            !_vm.filterReseller &&
            !_vm.filterRecipient &&
            _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS)
          )?_c('reseller-selector',{on:{"resellerChanged":(resellerId) => (_vm.selectedReseller = resellerId)}}):_vm._e(),_c('base-input',{staticClass:"dates",attrs:{"placeholder":"Dates"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
              allowInput: true,
              mode: 'range',
              locale: _vm.flatPickrLocale,
            },"placeholder":"Dates"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)],1),_c('div',{staticClass:"page-wrapper-table-header-right"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":_vm.$t('COMMON.PER_PAGE')},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1)],1)]),(_vm.resellerInvoices.length > 0)?_c('div',{staticClass:"page-wrapper-table-body"},[_c('div',{staticClass:"page-wrapper-table-body-inner"},[_c('el-table',{staticClass:"align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.resellerInvoices},on:{"sort-change":_vm.sortChange}},[(_vm.loading)?_c('div',{staticClass:"table-loading",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.CODE'),"prop":"code","sortable":"custom","min-width":"200"}}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.AMOUNT'),"prop":"pricing.total","min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',[_vm._v(" "+_vm._s(_vm.$formatCurrency(row.pricing.total))+" ")])]}}],null,false,2462263713)}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.RECIPIENT'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('object-link',{attrs:{"object":row.recipient}})]}}],null,false,4193633373)}),(
              !_vm.filterReseller &&
              _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS)
            )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.RESELLER'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('reseller',{attrs:{"reseller":row.reseller}})]}}],null,false,63323229)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('RESELLER_INVOICES.EXPIRATION_TIME'),"prop":"expiration_time","sortable":"custom","min-width":"180"}}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.CREATED_AT'),"prop":"created_at","sortable":"custom","min-width":"180"}}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.STATUS'),"prop":"status","min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('reseller-invoice-status-badge',{attrs:{"resellerInvoice":row}})]}}],null,false,1774435129)}),_c('el-table-column',{attrs:{"fixed":"right","min-width":"70"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{staticClass:"table-actions"},[_c('el-dropdown',{attrs:{"trigger":"click"}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"far fa-ellipsis-v"})]),_c('el-dropdown-menu',{staticClass:"actions-dropdown",attrs:{"slot":"dropdown"},slot:"dropdown"},[(
                      _vm.$currentUserCan(
                        _vm.$permissions.PERM_VIEW_RESELLER_INVOICES
                      )
                    )?_c('el-dropdown-item',[_c('a',{staticClass:"table-action view",attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.viewResellerInvoice(row)}}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../../../../../public/img/kw-eye.svg"),"alt":"icon"}})]),_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("COMMON.VIEW_DETAILS")))])])]):_vm._e(),(
                      _vm.$currentUserCan(
                        _vm.$permissions.PERM_EDIT_RESELLER_INVOICES
                      ) && row.status == _vm.INVOICE_STATUS_DRAFT
                    )?_c('el-dropdown-item',[_c('a',{staticClass:"table-action edit",attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.editResellerInvoice(row)}}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../../../../../public/img/kw-edit.svg"),"alt":"icon"}})]),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.EDIT"))+" ")])])]):_vm._e(),(
                      _vm.$currentUserCan(
                        _vm.$permissions.PERM_DELETE_RESELLER_INVOICES
                      ) && row.status == _vm.INVOICE_STATUS_DRAFT
                    )?_c('el-dropdown-item',[_c('a',{staticClass:"table-action delete",attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.deleteResellerInvoice(row.id)}}},[_c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../../../../../public/img/kw-delete.svg"),"alt":"icon"}})]),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DELETE"))+" ")])])]):_vm._e()],1)],1)],1)}}],null,false,1528099489)})],1)],1)]):_vm._e(),(_vm.resellerInvoices.length > 0)?_c('div',{staticClass:"page-wrapper-table-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('p',{staticClass:"card-category"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", { from: _vm.total ? _vm.from + 1 : 0, to: _vm.to, of: _vm.total, }))+" "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.$t("COMMON.X_LINES_SELECTED", { count: _vm.selectedRows.length }))+" ")]):_vm._e()]),_c('base-pagination',{staticClass:"pagination-no-binvoice",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1):_vm._e()]),(_vm.resellerInvoices.length === 0)?_c('div',{staticClass:"no-data"},[_c('div',{staticClass:"no-data-inner"},[_c('img',{attrs:{"src":require("../../../../../../public/img/kw-empty-box.svg"),"alt":"icon"}}),_c('p',[_vm._v(_vm._s(_vm.$t("RESELLER_INVOICES.NO_DATA")))]),(_vm.$currentUserCan(_vm.$permissions.PERM_CREATE_RESELLER_INVOICES))?_c('base-button',{staticClass:"kw-button add",attrs:{"icon":"","size":"sm"},on:{"click":_vm.onAddResellerInvoice}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"far fa-plus"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(_vm._s(_vm.$t("RESELLER_INVOICES.ADD_RESELLER_INVOICE")))])]):_vm._e()],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }